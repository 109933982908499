import React, { useCallback } from "react";
import Button from '../Button';
import Slider from "react-slick";
import { useStaticQuery, graphql, Link } from "gatsby"
import { abbreviateNumber, floatToPercentage } from '../../utils/formatNumbers' 

export default function ProductRange({widget}) {
	/*fieldGroupName
	  layout
      title
      button {
        target
        title
        url
      }*/

  //grab the products from the json feed
  const staticQuery = useStaticQuery(graphql`
    query {
	  json {
	    products {
		  productRange
	      rate
	      baseRate
	      maxLtv
		  minLoan
		  maxLoan
	    }
	  }
	  wp {
	    acfOptionsOptions {
	      options {
	        pagesProducts {
	          ... on WpPage {
	            uri
	          }
	        }
	      }
	    }
	  }
    }
  `)
  
  const productPageLink = staticQuery?.wp?.acfOptionsOptions?.options?.pagesProducts?.uri 
  							? staticQuery.wp.acfOptionsOptions.options.pagesProducts.uri  
  							: '';

  /**
   * Gets the unique list of product range values
   */
  const getUniqueProductRanges = useCallback(() => {
	    
	  var values = [];

	  if (staticQuery?.json?.products?.length>0) {
		  staticQuery.json.products.forEach(function(product) {
			if (product.productRange) {
				values.push(product.productRange)
			}
		  });
	  }

	  //get unique items
	  var uniqueValues = [...new Set(values)];

	  //sort
	  uniqueValues.sort((a, b) => a.localeCompare(b));

	  return uniqueValues;
	    
  }, [staticQuery]);

  /**
   * Gets the lowest baserate for a specified product range
   */
  const getMinBaseRate = useCallback((range) => {
	    
	  let minValue = false,
	  	  minPlusBBRValue = false,
	  	  allProductsArePlusBBR = true;

	  if (staticQuery?.json?.products?.length>0) {	
			
		  staticQuery.json.products.forEach(function(product) {	
			if (product.productRange===range && product.rate) {	
				if (!product.hasOwnProperty('baseRate') || product.baseRate!=='BBR') {
					allProductsArePlusBBR = false;
				}
				
				//if the rate is +BBR
				if (product.hasOwnProperty('baseRate') && product.baseRate!==null) {
					if (product.rate<minPlusBBRValue || minPlusBBRValue===false) {
						minPlusBBRValue = product.rate;
					}
				} else if (product.rate<minValue || minValue===false) {
					minValue = product.rate;
				}
			}
			
		  });
		  
		  if (allProductsArePlusBBR) {
			return floatToPercentage(minPlusBBRValue)+'+BBR';
			  
		  } else {
			return floatToPercentage(minValue);
		  }
	  }
	    
  }, [staticQuery]);

  /**
   * Gets the lowest value of a specified field for a specified product range
   */
  const getMinValue = useCallback((field, range) => {
	    
	  var minValue = false;

	  if (staticQuery?.json?.products?.length>0) {		
		
		  staticQuery.json.products.forEach(function(product) {			
			
			if (product.productRange===range && product[field] && (product[field]<minValue || minValue===false)) {
				minValue = product[field];
			}
		  });
	  }

	  return minValue;
	    
  }, [staticQuery]);

  /**
   * Gets the highest value of a specified field for a specified product range
   */
  const getMaxValue = useCallback((field, range) => {
	    
	  var maxValue = false;

	  if (staticQuery?.json?.products?.length>0) {
		  staticQuery.json.products.forEach(function(product) {
			if (product.productRange===range && product[field] && (product[field]>maxValue || maxValue===false)) {
				maxValue = product[field];
			}
		  });
	  }

	  return maxValue;
	    
  }, [staticQuery]);

  const ProductRange = ({range}) => (
	<div className="product-wrap">
		<article>
			<header>
				<h3>{range}</h3>
			</header>
			<div className="content">
				{/*<p>{data.productType}</p>*/}
				<table>
					<tbody>
					  <tr>
					    <th>Rates from</th>
					    <td>{getMinBaseRate(range)}</td>
					  </tr>
					  <tr>
					    <th>Max LTV</th>
					    <td>{floatToPercentage(getMaxValue('maxLtv', range))}</td>
					  </tr>
					  <tr>
					    <th>Min loan</th>
					    <td>{abbreviateNumber(getMinValue('minLoan', range)/100)}</td>
					  </tr>
					  <tr>
					    <th>Max loan</th>
					    <td>{abbreviateNumber(getMaxValue('maxLoan', range)/100)}</td>
					  </tr>
					</tbody>
				</table>
			</div>
			<footer>
				<Link to={productPageLink} state={{ productRange: range }} className="button solid purple small">View range</Link>
			</footer>
		</article>
	</div>
  );

  const carouselSettings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
	responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
    	  arrows: false,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
    	  centerMode: true,
		  centerPadding: '32px',
    	  arrows: false,
        }
      }
    ]
  };

  const productRanges = getUniqueProductRanges();

    return(
		<section className={widget.layout==='yellow' ? 'widget product-range yellow' : 'widget product-range grey'}>	
			<div className="internal">
        		<h2>{widget.title}</h2>
				<div className="products">	
				  <Slider {...carouselSettings}>
					{productRanges.length>0 && productRanges.map((productRange, i) => (					    
				    	<ProductRange key={`product${i}`} range={productRange} />
				    ))}
				  </Slider>	
				</div>
				{
				  widget.button
				  ? <p className="buttonp"><Button link={widget.button} className="solid white" /></p>			  
				  : null
				}	
			</div>
		</section>
    )
}

